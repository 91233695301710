<template>
  <b-card title="Download Apps">

      <!-- 12 INVOICES HAVE BEEN PAID -->

  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
  },
}
</script>

<style>

</style>
